import Link from 'next/link';

const PageNotFound = () => {

	return (
		<>
			<div className='pageNotFound'>
				<h1> Page Not Found</h1>
				<h3>
					Check to see if your are in the correct page.
				</h3>
				<Link href='/'>Click here to return home</Link>
			</div>
		</>
	)
}

export default PageNotFound;